

export default class InspectionUtils {

  static SortByCreatedDate = function (a, b) {
    return InspectionUtils.SortBy_createdDateAz(a,b);
  }

  static SortBy_name = function (a, b) {
    return InspectionUtils.SortBy_nameAz(a, b);
  }

  static SortBy_nameAz = function (a, b) {
    return InspectionUtils.SortBy("name", a, b);
  }

  static SortBy_nameZa = function (a, b) {
    return InspectionUtils.SortByDesc("name", a, b);
  }

  static SortBy_createdDateAz = function (a, b) {
    return InspectionUtils.SortBy("createdDate", a, b);
  }

  static SortBy_createdDateZa = function (a, b) {
    return InspectionUtils.SortByDesc("createdDate", a, b);
  }

  static SortBy_contractStartDateAz = function (a, b) {
    return InspectionUtils.SortBy("contractStart", a, b);
  }

  static SortBy_contractStartDateZa = function (a, b) {
    return InspectionUtils.SortByDesc("contractStart", a, b);
  }

  static SortBy = function(methodName, a, b) {
    if (a == null || b == null) {
      return 0;
    }
    
    let aValue = InspectionUtils.resultFromFunction(a[methodName]());
    let bValue = InspectionUtils.resultFromFunction(b[methodName]());

    if (aValue < bValue) {
      return -1;
    }

    if (aValue > bValue) {
      return 1;
    }

    return 0;
  }

  static SortByDesc = function(methodName, a, b) {
    if (a == null || b == null) {
      return 0;
    }

    let aValue = InspectionUtils.resultFromFunction(a[methodName]());
    let bValue = InspectionUtils.resultFromFunction(b[methodName]());

    if (aValue < bValue) {
      return 1;
    }

    if (aValue > bValue) {
      return -1;
    }

    return 0;
  }

  static resultFromFunction = function(aFunctionOrValue) {
    if (typeof aFunction === 'function') {
      return InspectionUtils.resultFromFunction(aFunctionOrValue());
    }
    if (aFunctionOrValue.time) {
      return aFunctionOrValue.time();
    }
    return aFunctionOrValue
  }

}